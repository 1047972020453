import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { buttonReset, flexCenter } from '@styles/mixins'

export const InputStyled = styled('div')`
  width: 100%;
  padding: 1rem 0;
  input {
    width: 100%;
    box-sizing: border-box;
    border: none;
    outline: none;
    padding: 0.5rem 1rem;
    color: ${props => props.theme.colors.white};
    background: ${props => chroma(props.theme.colors.white).alpha(0.1).css()};
    border-bottom: 1px solid
      ${props => (props.required ? chroma(props.theme.colors.red).alpha(0.8).css() : chroma(props.theme.colors.green).alpha(0.8).css())};
    transition: border-color 0.5s ease;

    &:focus {
      border-color: ${props => props.theme.colors.primary};
    }

    &::placeholder {
      color: ${props => chroma(props.theme.colors.white).alpha(0.5).css()};
    }
  }
`

export const ButtonStyled = styled('button')`
  ${props => buttonReset(props)}
  ${flexCenter}
  margin: .5rem;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  background: ${props => props.theme.colors.orange};
  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.primaryLighter};
  }
  &:disabled {
    color: ${props => chroma(props.theme.colors.muted).brighten(1).css()};
    background: ${props => chroma(props.theme.colors.muted).darken(1).css()};
  }
`

export const ButtonsGroup = styled('div')`
  display: flex;
  flex-direction: row;
  float: right;
`

export const Wrapper = styled('div')`
  width: 100%;
  max-width: 600px;
  padding: 1rem;
  padding-top: 50px;

  .errors {
    color: red;
  }
`
