<template>
  <PasteIdsOverlayStyled>
    <Wrapper>
      <header>AssetIds</header>
      <textarea v-model="assetIdlist" placeholder="Paste the asset IDs here"> </textarea>
      <ButtonsGroup>
        <ButtonStyled :disabled="!assetIdlist" @click="formatAsStrings">
          {{ $tc('actions.add') }}
        </ButtonStyled>
        <ButtonStyled :disabled="isLoading" color="grey" @click="$root.$emit('closeOverlay')">
          {{ $tc('actions.cancel') }}
        </ButtonStyled>
      </ButtonsGroup>
    </Wrapper>
  </PasteIdsOverlayStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { Wrapper, ButtonsGroup, ButtonStyled } from './styles'
import { input } from '@styles/mixins'
import chroma from 'chroma-js'
import { FlashMessages } from '@common/singletons'

import ADD_MANY_ASSETS_TO_ORGANIZATION from '#/graphql/organizations/addManyAssetsToOrganisation.gql'
import ADD_MANY_ASSETS_TO_SUBSIDIARY from '#/graphql/organizations/addManyAssetsToSubsidiary.gql'

const PasteIdsOverlayStyled = styled('div')`
  textarea {
    ${input}
    padding: 0.5rem;
    height: 20rem;
    resize: none;
    background: none;
    font-family: 'Open Sans', sans-serif;
    color: ${p => p.theme.colors.primary};
    border: 1px solid ${p => chroma(p.theme.colors.navFontNormal).alpha(0.8).css()};
    transition: border-color 0.5s ease;
    &:focus {
      border-color: ${p => p.theme.colors.primary};
    }
    width: 93%;
  }
`

export default {
  props: {
    args: {
      type: Object,
    },
  },
  components: {
    PasteIdsOverlayStyled,
    Wrapper,
    ButtonsGroup,
    ButtonStyled,
  },
  data() {
    return {
      assetIdlist: '',
      isLoading: false,
      invalidUUIDs: [],
    }
  },
  methods: {
    validateUUIDs(formattedValues) {
      const UUIDregex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/

      const validUUIDs = []
      const invalidUUIDs = []

      formattedValues.forEach(uuid => {
        if (UUIDregex.test(uuid)) {
          validUUIDs.push(uuid)
        } else {
          invalidUUIDs.push(uuid)
        }
      })

      return { validUUIDs, invalidUUIDs }
    },
    formatAsStrings() {
      if (!this.assetIdlist) {
        return
      }
      const assetIdsTrimmed = this.assetIdlist.replace(/["']/g, '')

      const formattedValues = assetIdsTrimmed.split(',').map(value => value.trim())
      const { validUUIDs, invalidUUIDs } = this.validateUUIDs(formattedValues)
      if (invalidUUIDs.length > 0) {
        FlashMessages.$emit('error', new Error(`Could not add ${invalidUUIDs} : invalid UUID`), {
          timeout: 1500,
        })
      } else if (this.args.mode === 'addAssetsToSubsidiary') {
        this.addAssetsToSubsidiary(validUUIDs)
      } else {
        this.addAssetsToOrganization(validUUIDs)
      }
    },

    async addAssetsToOrganization(assetIds) {
      const organizationID = this.$route.params.id
      if (!organizationID || assetIds.length < 1) {
        return
      }
      try {
        const res = await this.$apollo.mutate({
          mutation: ADD_MANY_ASSETS_TO_ORGANIZATION,
          variables: {
            organizationId: organizationID,
            assetIds: assetIds,
          },
        })
        FlashMessages.$emit('success', res.data.addAssetsToOrganization, {
          timeout: 3000,
        })
        this.$root.$emit('closeOverlay')
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
    async addAssetsToSubsidiary(assetIds) {
      const subsidiaryId = this.$route.params.id
      if (!subsidiaryId || assetIds.length < 1) {
        return
      }
      try {
        const res = await this.$apollo.mutate({
          mutation: ADD_MANY_ASSETS_TO_SUBSIDIARY,
          variables: {
            subsidiaryId: subsidiaryId,
            assetIds: assetIds,
          },
        })
        FlashMessages.$emit('success', res.data.addAssetsToSubsidiary, {
          timeout: 3000,
        })
        this.$root.$emit('closeOverlay')
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
  },
}
</script>
