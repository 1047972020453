var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PasteIdsOverlayStyled', [_c('Wrapper', [_c('header', [_vm._v("AssetIds")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.assetIdlist,
      expression: "assetIdlist"
    }],
    attrs: {
      "placeholder": "Paste the asset IDs here"
    },
    domProps: {
      "value": _vm.assetIdlist
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.assetIdlist = $event.target.value;
      }
    }
  }), _c('ButtonsGroup', [_c('ButtonStyled', {
    attrs: {
      "disabled": !_vm.assetIdlist
    },
    on: {
      "click": _vm.formatAsStrings
    }
  }, [_vm._v(" " + _vm._s(_vm.$tc('actions.add')) + " ")]), _c('ButtonStyled', {
    attrs: {
      "disabled": _vm.isLoading,
      "color": "grey"
    },
    on: {
      "click": function click($event) {
        return _vm.$root.$emit('closeOverlay');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$tc('actions.cancel')) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }